import './bootstrap'
import './sprite'
import { navigation } from './modules'
import { tinySliders } from './modules'
import { accordion } from './modules'
import { video } from './modules'
import { tabs } from './modules'
import { dropdown } from './modules'
import { scrollTo } from './modules'
import { form } from './modules'
import { search } from './modules'
import { notificationBar } from './modules'
import { compare } from './modules'
import { languageSwitch } from './modules'
import { listingFilters } from './modules'
import { blogFilters } from './modules'
import { storeLocator } from './modules'
import { product } from './modules'
import { blogDetailHeaders } from './modules'
import { downloadSelect } from './modules'
import { newsletterPopup } from './modules'
import { nitro } from './modules'
import { visoSignup } from './modules'
import { enableVisoButtons } from './modules'
import { nitroForms } from './modules'
import { recaptcha } from './modules'

import.meta.glob([
    '../images/**'
])

// Some functionalities should be loaded immediately instead of on init.
recaptcha()
tinySliders()

document.addEventListener('DOMContentLoaded', () => {
    blogDetailHeaders()
    navigation()
    accordion()
    video()
    tabs()
    dropdown()
    scrollTo()
    form()
    search()
    storeLocator()
    notificationBar()
    compare.init()
    languageSwitch()
    listingFilters()
    blogFilters()
    product()
    downloadSelect()
    newsletterPopup()
    nitro()
    nitroForms()
    visoSignup()
    enableVisoButtons()
})
